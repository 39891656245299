import { REPORTS_SERVICE } from '../../const';
import angular from 'angular';
import { hasAccess } from '../../reselect';
import c3 from '../../plugins/c3/c3.js';
import { setLinkings } from '../../toolkit/actions';

class ReportTargetsModalController {
    constructor ($timeout, $scope, $filter, $localStorage, ReportsService, selectedData, $ngRedux) {
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$filter = $filter;
        this.$localStorage = $localStorage;
        this.ReportsService = ReportsService;
        this.selectedData = selectedData;
        this.isLoading = false;
        this.currentDataIndex = null;
        this.currentData = null;
        this.c3 = c3;
        this.chartData = null;
        this.random = new Date().getTime();
        this.showTargetList = {};
        this.firstLoading = true;
        this.appCtrl = window.appCtrl;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        window['reportTargetsModalCtrl' + this.random] = this;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setLinkings })(this);
    }

    mapStateToThis = (state) => {
        return {
            hashPath: state.linking.link,
            targetDownloadAccess: hasAccess(state, { sectionId: 1, rightName: 'target_download' })
        };
    };

    $onInit () {
        this.isLoading = true;
        this.errorText = false;
        this.ReportsService.targetsHistory(this.selectedData, this, (ctrl, response) => {
            if (response.data) {
                ctrl.data = response.data;
                ctrl.skills = response.skills;
                ctrl.totalTargets = response.totalTargets;
                ctrl.totalDepartments = response.totalDepartments;
                ctrl.targetsNames = response.targetsNames;
                ctrl.departmentsNames = response.departmentsNames;
                ctrl.currentDataIndex = ctrl.data.length - 1;
                ctrl.$timeout(() => {
                    ctrl.showData();
                    ctrl.$timeout(ctrl.showGraph(), 1);
                }, 1);
                ctrl.$localStorage.reportData = null;
                ctrl.firstLoading = false;
            }
            ctrl.isLoading = false;
        }, (ctrl, response) => {
            if (response && response.data && response.data.error) {
                ctrl.errorText = response.data.error;
            } else {
                ctrl.errorText = this.gettextCatalog.getString('Ошибка при загрузке истории');
            }
            ctrl.$localStorage.reportData = null;
            ctrl.isLoading = false;
        });
    }

    downloadReport = () => {
        if (!this.targetDownloadAccess) return;
        let path = `${window.config.SYSTEM_URL}${window.config.API_URL}/reports/download/select?selectedData=${angular.toJson(this.selectedData)}&csrfToken=${this.getCsrfToken()}`;
        window.location = path;
        this.setLinkings('');
    };

    normalize = (data) => {
        let successMax = 1;
        let failMax = 1;
        let skills = Object.keys(this.skills);
        angular.forEach(skills, (skill) => {
            data[skill]['nSuccess'] = data[skill]['success'] > 7 ? 7 : data[skill]['success'];
            data[skill]['nFail'] = data[skill]['fail'] > 7 ? 7 : data[skill]['fail'];
            successMax = data[skill]['success'] > successMax ? data[skill]['success'] : successMax;
            failMax = data[skill]['fail'] > failMax ? data[skill]['fail'] : failMax;
        });
        angular.forEach(skills, (skill) => {
            if (successMax > 7) {
                data[skill]['nSuccess'] = data[skill]['nSuccess'] * data[skill]['success'] / successMax;
            }
            if (failMax > 7) {
                data[skill]['nFail'] = data[skill]['nFail'] * data[skill]['fail'] / failMax;
            }
            if (data[skill]['nSuccess'] < 1 && data[skill]['nSuccess'] != 0) {
                data[skill]['nSuccess'] = 1;
            }
            if (data[skill]['nFail'] < 1 && data[skill]['nFail'] != 0) {
                data[skill]['nFail'] = 1;
            }
        });
        return data;
    };

    showData = () => {
        if (this.data[this.currentDataIndex] && this.data[this.currentDataIndex].ratingData) {
            this.currentData = this.normalize(this.data[this.currentDataIndex].ratingData);
            this.$timeout(() => {
                this.$scope.$apply();
            }, 1);
        }
    };

    showGraph = () => {
        let c3 = this.c3;
        let data = ['data1'];
        let data2 = ['data2'];
        let date = ['date'];
        let x = ['x1'];
        let x2 = ['x2'];
        let x3 = ['x3'];
        let index = 0;

        angular.forEach(this.data, (item) => {
            if (!angular.isUndefined(item.ratingData.total)) {
                x.push(index);
                data.push(item.ratingData.total / item.ratingData.count / item.ratingData.skills);
                if (item.ratingData.count == this.totalTargets) {
                    x2.push(index);
                    data2.push(item.ratingData.total / item.ratingData.count / item.ratingData.skills);
                }
                date.push(item.ratingData.total / item.ratingData.count / item.ratingData.skills);
                x3.push(index);
                index++;
            }
        });
        this.chart = c3.generate({
            bindto: this.chartElement,
            data: {
                xs: {
                    'data2': 'x2',
                    'data1': 'x1',
                    'date': 'x3'
                },
                columns: [
                    x,
                    x2,
                    x3,
                    data2,
                    data,
                    date
                ],
                selection: {
                    enabled: true,
                    multiple: false
                },
                onselected: (item) => {
                    this.currentDataIndex = item.x;
                    this.$timeout(this.showData(), 1);
                },
                onmouseover: () => {
                },
                colors: {
                    data: '#007eff',
                    data2: '#ff8500'
                }
            },
            legend: {
                show: false
            },
            axis: {
                y: {
                    show: false,
                    min: -1,
                    max: 1
                },
                x: {
                    show: false
                }
            },
            grid: {
                y: {
                    lines: [
                        { value: 0, class: 'null' },
                        { value: 1, class: 'max' },
                        { value: -1, class: 'min' }
                    ]
                }
            },
            tooltip: {
                format: {
                    title: (x) => {
                        return this.data[x].name;
                    },
                    value: () => {
                        return '';
                    },
                    name: (name, ratio, id, index) => {
                        if (name == 'data1') {
                            return this.gettextCatalog.getString('Сотрудники:') + ' ' + this.data[index].ratingData.count + ' ' + this.gettextCatalog.getString('из') + ' ' + this.totalTargets;
                        } else if (name == 'data2') {
                            return this.gettextCatalog.getString('Все сотрудники');
                        } else if (name == 'date') {
                            return this.$filter('dateFormat')(this.data[index].date, 'd MMMM HH:mm');
                        }
                    }
                }
            },
            zoom: {
                enabled: true
            }
        });
        this.chart.select(['data1'], [data.length - 2], true);
    };

    chartCallback = (chart) => {
        this.chartElement = chart;
        this.showGraph();
    };

    setSelectedData = (selectedData) => {
        this.selectedData = selectedData;
        angular.forEach(Object.keys(this.showTargetList), (skill) => {
            this.showTargetList[skill] = false;
        });
        this.$onInit();
    };

    editSelected = () => {
        this.$localStorage.reportData = {
            selectedData: this.selectedData
        };
        this.appCtrl.go('targets');
        this.$close(this.selectedData);
    };

    getCsrfToken = () => {
        return window.csrfToken;
    };
}

ReportTargetsModalController.$inject = [
    '$timeout',
    '$scope',
    '$filter',
    '$localStorage',
    REPORTS_SERVICE,
    'selectedData',
    '$ngRedux'
];

export {
    ReportTargetsModalController
};
