import React from 'react';

export function TableRow({ children, handleClick }) {
    return (
        <tr data-testid={'ui-table-table_row'}
            className='react-table_row'
            onClick={() => handleClick()}
        >
            {children}
        </tr>
    );
}
