import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    status: "idle",
    courses: [],
    error: false,
    loading: false,
    deleteLoading: false,
    deleteCoursesList: []
};

export const fetchCoursesSettings = createAsyncThunk("coursesSettings/status", async () => {
    const api = FetchApi(`/courses/setting`);
    let courses = await api.get();
    if (courses.isError) {
        throw courses;
    }
    courses = courses.length
        ? courses.map((item) => {
              item.checked = false;
              return item;
          })
        : [];
    return [...courses];
});

export const deleteCourses = createAsyncThunk("courses/delete", async (ids) => {
    const fetchApi = FetchApi(`/courses/bulk-delete`);
    const response = await fetchApi.post({
        body: {
            coursesId: ids
        }
    });
    return ids;
});


const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        setCourses(state, { payload }) {
            state.courses = payload;
        },
        addCourse(state, { payload }) {
            state.courses.unshift({...payload, checked: false});
        },
        updateCourse(state, { payload }) {
            state.courses = state.courses.map((item) => {
                if (item.id !== payload.id) return item;
                return payload;
            });
        },
        updateCoursesChecked(state, { payload }) {
            state.courses = state.courses.map((item) => {
                if (item.id === payload) {
                    return {
                        ...item,
                        checked: !item.checked,
                    };
                } else {
                    return item;
                }
            });
        },
        deleteCoursesFromList(state, { payload }) {
            state.deleteCoursesList = state.courses.filter(course => payload?.includes(course.id))
            state.courses = state.courses.filter((v) => {
                return !payload?.includes(v.id);
            });
        },
        returnCourse(state, { payload }) {
            state.courses.unshift(...payload)
        }
    },
    extraReducers: {
        [fetchCoursesSettings.pending]: (state) => {
            state.loading = true;
        },
        [fetchCoursesSettings.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.courses = payload;
        },
        [fetchCoursesSettings.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
        [deleteCourses.pending]: (state) => {
            state.deleteLoading = true;
        },
        [deleteCourses.fulfilled]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = false;
            // state.courses = state.courses.filter((v) => {
            //     return !payload.includes(v.id);
            // });
        },
        [deleteCourses.rejected]: (state, { payload }) => {
            state.deleteLoading = false;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const {
    setCourses,
    addCourse,
    updateCourse,
    updateCoursesChecked,
    deleteCoursesFromList,
    returnCourse
} = coursesSlice.actions;
export default coursesSlice.reducer;
