export const notificationSchema = ({ handleRow, iconHandle, disabled }) => {
    let schema = {
        row: {
            handleRow,
            disabled: (id) => disabled.indexOf(id) !== -1 ? 'react-table_cell__disabled' : ''
        },
        cell: [
            {
                text: 'name',
            }
        ],
        icon: [
            {
                isIcon: true,
                icon: 'edit pause',
                handle: iconHandle
            }
        ],
        changeIcon: (id) => disabled.indexOf(id) !== -1 ? 'edit play' : 'edit pause'
    };
    return schema;
};
