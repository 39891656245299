import React, { useContext, useState } from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { RoleModal } from 'root/assets/webpack/src/js/components/roleModal';
import { useDispatch, useSelector } from 'react-redux';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import FetchApi from '../../REST';
import { editRole, setNewRole } from '../../../toolkit/actions';
import { deleteRole, fetchRoles } from '../../../toolkit/think';
import { hasAccess } from 'root/assets/webpack/src/js/reselect';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'rootReact/UI/Table';
import ReactIcon from 'rootReact/components/ReactIcons';
import { usePlural } from 'rootReact/hook';
import FaIcon from 'rootReact/components/FaIcon';
import style from './settingsRole.module.less';
import Translate from 'rootReact/Translate.jsx';

function SettingsRole({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const roles = useSelector(state => state.roleUsers.roles);
    const roleStatus = useSelector(state => state.roleUsers.status);
    const profilesLdap = useSelector(state => state.settings.settings.ldapProfiles);
    const [syncStatus, setSyncStatus] = useState({});
    const [showIndex, setShowIndex] = useState(10);
    const dispatch = useDispatch();
    const roleSaveAccess = useSelector(state => hasAccess(state, { sectionId: 102, rightName: 'role_save' }));
    const roleDeleteAccess = useSelector(state => hasAccess(state, { sectionId: 102, rightName: 'role_delete' }));
    const userSaveAccess = useSelector(state => hasAccess(state, { sectionId: 101, rightName: 'user_save' }));
    const userDeleteAccess = useSelector(state => hasAccess(state, { sectionId: 101, rightName: 'user_delete' }));

    const getHeaderLink = () => {
        return (
            <div>
                <a data-testid={'settings-role-add-role'} onClick={() => {
                    if (!roleSaveAccess || !licenseIsValid) return;
                    return handleRole(0, profilesLdap);
                }}
                className={`settings_path-right ${(!licenseIsValid || !roleSaveAccess) ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
            </div>
        );
    };

    const openModal = (id, profilesLdap) => {
        let modal = injector.instantiate(RoleModal);
        return modal.open(
            {
                roleId: id,
                ldapProfilesList: profilesLdap ? profilesLdap : [],
            }
        );
    };

    const handleRole = async (id, profilesLdap) => {
        const roleModal = await openModal(id,profilesLdap);
        if (!roleModal) return;
        let role = await roleModal.json();
        if (!id) {
            dispatch(setNewRole(role));
        } else {
            dispatch(editRole(role));
        }
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        if (confirm(gettext.getString('Удалить роль?'))) {
            dispatch(deleteRole(id));
        }
    };
    const headerNames = ['Название', 'Профили LDAP', 'Группы пользователей', 'Синхронизация'];

    const getTypeOfSync = (role) => {
        const endingHours = (hours = 0) => [
            gettext.getString('каждый {{count}} час', { count: hours }),
            gettext.getString('каждые {{count}} часа', { count: hours }),
            gettext.getString('каждые {{count}} часов', { count: hours }),
        ];
        const endingDays = (days = 0) => [
            gettext.getString('каждый {{count}} день', { count: days }),
            gettext.getString('каждые {{count}} дня', { count: days }),
            gettext.getString('каждые {{count}} дней', { count: days }),
        ];

        if (!role.syncParameters) return '';
        if (role.syncParameters.mode === 2) {
            return gettext.getString('Вручную');
        }
        let hours = role.syncParameters.synchronizeInterval / 60 / 60;
        if (hours / 24 === (hours / 24 ^ 0)) {
            return usePlural(hours / 24, endingDays(hours/24));
        }
        return usePlural(hours, endingHours(hours));
    };

    const syncLdap = async (e, id) => {
        e.stopPropagation();
        setSyncStatus({ ...syncStatus, [id]: 'inProcess' });
        const fetchApi = FetchApi('/roles/sync');
        let response = await fetchApi.post({ body: { id } });
        if (response.isError) {
            setSyncStatus({ [id]: 'error' });
        } else {
            setSyncStatus({ [id]: 'ready' });
        }
    };

    const getSyncStatus = (role, syncFunction) => {
        if (!role.syncParameters || (!licenseIsValid || !userSaveAccess || !userDeleteAccess)) return '';
        if (syncStatus[role.id] === 'ready' || !syncStatus[role.id]) {
            return <a data-testid={'settings-role-launch-sync'} onClick={(e) => syncFunction(e, role.id)}>{gettext.getString('Запустить сейчас')}</a>;
        }
        if (syncStatus[role.id] === 'inProcess') {
            return <FaIcon type={'pending'} text={gettext.getString('Синхронизация') + '...'}/>;
        }
        if (syncStatus[role.id] === 'error') {
            return <span><span className={style.cell}>{gettext.getString('ошибка:')}</span> <a data-testid={'settings-role-resync'} onClick={(e) => syncFunction(e, role.id)}>{gettext.getString('Повторить')}</a></span>;
        }
    };

    const getLdapProfilesString = (role, ldapProfiles) => {
        if(!ldapProfiles || role.ldapSyncRefs.length === 0) return '';
        return role.ldapSyncRefs.reduce((accum, currValue) => {
            const elem = ldapProfiles.find(ldap => ldap.id === currValue.profileId);
            if (elem) {
                return accum + (accum ? ', ' : '') + elem.title;
            } else return accum;
        }, '');
    };

    const getFooterTable = () => {
        if (roles.length <= 10) return;
        if (roles.length > showIndex) {
            return (<div style={{ paddingLeft: '.5rem' }}>
                <a onClick={() => setShowIndex(showIndex + 10)}
                    className={style.showMore}
                    data-testid={'roles-show-more'}
                >
                    <Translate>Показать еще</Translate> ↓
                </a>
                <a onClick={() => setShowIndex(roles.length)}
                    data-testid={'roles-show-all'}
                >
                    {gettext.getString('Показать все')} ↓
                </a>
            </div>);
        } else {
            return (<div style={{ paddingLeft: '.5rem' }}>
                <a onClick={() => setShowIndex(10)}><Translate>Свернуть</Translate> ↑</a>
            </div>);
        }
    };

    const getGroupOfUsersString = (role) => {
        if(role.ldapSyncRefs.length === 0) return '';
        return role.ldapSyncRefs.reduce((accum, currValue) => [...new Set(accum.concat(currValue.groupNames))], []).join(', ');
    };

    const setRoleStatus = () => {
        return roleStatus === 'pending' ? <div className={'loader'}/>
            : roleStatus === 'rejected' ? <>{gettext.getString('Что-то пошло не так')}
                <a style={{ marginLeft: '1rem' }} onClick={() => dispatch(fetchRoles())}>{gettext.getString('Повторить')}</a> </>
                : gettext.getString('Нет ролей');
    };

    return (
        <div className={style.wrapper}>
            <SettingsHeader title={gettext.getString('Роли администраторов')} right>{getHeaderLink()}</SettingsHeader>
            <div className={`settings_item ${style.settingItem}`}>
                <Table>
                    <TableHead>
                        {headerNames.map(name => (
                            <TableCell key={name} Style={'react-table_header-cell'}>{gettext.getString(name)}</TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody empty={roles.length === 0}
                        emptyText={setRoleStatus()}
                        emptyColSpan={5}
                    >
                        {roles.map((item, i) => {
                            if (i < showIndex) {
                                return (
                                    <TableRow key={item.id} handleClick={() => {
                                        if (!roleSaveAccess || !licenseIsValid) return openModal(item.id, profilesLdap);
                                        return handleRole(item.id, profilesLdap);
                                    }}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{getLdapProfilesString(item, profilesLdap)}
                                        </TableCell>
                                        <TableCell>{getGroupOfUsersString(item)}
                                        </TableCell>
                                        <TableCell Style={style.cell}>{getTypeOfSync(item)}</TableCell>
                                        <TableCell>{getSyncStatus(item, syncLdap)}</TableCell>
                                        <TableCell>{item.default || !licenseIsValid || !roleDeleteAccess ? '' :
                                            <ReactIcon handleClick={e => iconDelete(e, item.id)} icoClass={'delete'}/>}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        })}
                    </TableBody>
                </Table>
                {getFooterTable()}
            </div>
        </div>
    );
}

export default SettingsRole;
