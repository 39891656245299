import React from 'react';

export function TableCell({ children, Style }) {

    return (
        <td className={`react-table_cell ${Style}`}>
            {children}
        </td>
    );
}
